import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import "./index.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>We are currently rebuilding Tech Assess.</h1>
    <div className="alert offline">
      <div>
        <h4>Bloody Easy Tech Assessment</h4>
        <p>
          <strong>NOTE:</strong> Tech Assess is currently being redeveloped and
          will be available in spring, 2022.
        </p>
      </div>
      <div className="status">Offline</div>
    </div>
    <div className="alert offline">
      <div>
        <h4>Bloody Easy Audits</h4>
        <p>
          <strong>NOTE:</strong> Bloody Easy Audits will be reviewed and
          rebuilds of each audit will be prioritized based on demand. For manual
          audits please refer to the following printable audit forms:
          <ul>
            <li>
              <a href="https://transfusionontario.org/en/category/blood-utilization-audits/audits-supporting-documentation/ab-plasma-audit/">
                AB Plasma Audit
              </a>
            </li>
            <li>
              <a href="https://transfusionontario.org/en/category/blood-utilization-audits/audits-supporting-documentation/bedside-audit-2/">
                Bedside Audit
              </a>
            </li>
            <li>
              <a href="https://transfusionontario.org/en/category/blood-utilization-audits/audits-supporting-documentation/platelet-audit/">
                Platelet Audit
              </a>
            </li>
            <li>
              <a href="https://transfusionontario.org/en/category/blood-utilization-audits/audits-supporting-documentation/red-blood-cell/">
                Red Cell Audit
              </a>
            </li>
            <li>
              <a href="https://transfusionontario.org/en/category/blood-utilization-audits/audits-supporting-documentation/specimen-collection/">
                Specimen Collection Audit
              </a>
            </li>
          </ul>
        </p>
      </div>
      <div className="status">Offline</div>
    </div>
    <div className="alert online">
      <div>
        <h4>Bloody Easy Lite</h4>
        <p>
          <strong>NOTE:</strong> If you are looking to complete the Bloody Easy
          Lite eLearning, please reach out to the site administrator at your
          facility. If you are a site administrator and need access to the new
          LMS, or do not know who your site administrator is, please reach out
          to Troy Thompson at{" "}
          <a href="mailto:troy.thompson@sunnybrook.ca">
            troy.thompson@sunnybrook.ca
          </a>
          .
        </p>
      </div>
      <div className="status">Online</div>
    </div>
    <div className="alert online">
      <div>
        <h4>
          Bloody Easy Blood Administration <strong>eLearning</strong>
        </h4>
        <p>
          <strong>NOTE:</strong> If you are looking to complete the Bloody Easy
          Blood Administration eLearning, please reach out to the site
          administrator at your facility. If you are a site administrator and
          need access to the new LMS, or do not know who your site administrator
          is, please reach out to Troy Thompson at{" "}
          <a href="mailto:troy.thompson@sunnybrook.ca">
            troy.thompson@sunnybrook.ca
          </a>
          .
        </p>
      </div>
      <div className="status">Online</div>
    </div>
    <div className="alert online">
      <h4>IVIG app</h4>
      <div className="status">Online</div>
    </div>
    <div className="alert online">
      <h4>Platelet web application</h4>
      <div className="status">Online</div>
    </div>
    <div className="alert online">
      <div>
        <h4>Order resources functionality</h4>
        <a href="https://transfusionontario.org/en/order-resources/">
          Order resources here
        </a>
      </div>
      <div className="status">Online</div>
    </div>
    <div className="alert online">
      <div>transfusionontario.org website</div>
      <div className="status">Online</div>
    </div>
  </Layout>
)

export default IndexPage
